<template>
    <div class="row">
        <div class="meet-container">
            <b-button class="mb-3" @click="finish" variant="primary" v-if="user.role == 1 || user.id == trading_top.user_id">
                Finish class
            </b-button>
            <div ref="meet" class="meet-conference"></div>
        </div>
   </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['id', 'name'],
    mounted () {
        this.openRoom()
    },
    data () {
        return {
            room: null,
            loading: false
        }
    },
    created () {
        this.getClass()
    },
    methods: {
        ...mapActions('tradingLive', ['getTradingsLiveClass', 'togleStatus']),
        getClass() {
            this.loading = true
            this.getTradingsLiveClass(this.id).then(() => {
                this.loading = false
            })
        },
        finish() {
            this.loading = true
            this.togleStatus(this.id).then(() => {
                this.loading = false
                this.$router.push({ name: 'Manage-Trading-Live' })
            })
        },
        openRoom() {
            if (window.JitsiMeetExternalAPI) {
                this.room = this.name;
                this.startConference();
            } else {
                openNotification('Not conection')
            }
        },
        startConference() {
            const domain = 'meet.jit.si';
            const options = {
                audioInput: '<deviceLabel>',
                audioOutput: '<deviceLabel>',
                videoInput: '<deviceLabel>',
                roomName: this.room,
                parentNode: this.$refs.meet,
                interfaceConfigOverwrite: {
                TOOLBAR_BUTTONS: ['microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                    'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
                    'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                    'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
                    'tileview', 'videobackgroundblur', 'download', 'help'],
                filmStripOnly: false,
                SHOW_JITSI_WATERMARK: false,
                },
                configOverwrite: {
                defaultLanguage: 'es',
                disableSimulcast: false,
                },
                userInfo: {
                    email: this.user.email,
                    displayName: this.user.username
                }
            };

            let api = new JitsiMeetExternalAPI(domain, options);
            api.resizeLargeVideo('100%', '200px');
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('tradingLive', ['trading_top'])
    }
}
</script>
<style>
    .meet-conference{
        max-width: 80% !important;
        margin: auto;
        height: 70vh;
    }
</style>